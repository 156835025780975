import React from 'react';
import SEO from "../../../components/en/seo/Seo";
import Header from "../../../components/en/headers/Header";
import Footer from "../../../components/en/layouts/Footer";
import CallToAction from "../../../components/en/layouts/CallToAction";
import MineEventsHeader from "../../../components/en/mine-events/MineEventsHeader";
import MineEventDescription from "../../../components/en/mine-events/MineEventDescription";

function Events(props) {
    return (
        <div>
            <SEO title="Traditional Events"
                 description="As part of the December events in Litija, which we named Magic Litija, there is also a wide area of events in and before the Sitarjevec mine. Events are well attended and attract more and more visitors every year. They are inviting the visitors with differentness and uniqueness, and some with nostalgia and a pleasant atmosphere."/>
            <Header/>
            <MineEventsHeader/>
            <MineEventDescription/>
            <CallToAction/>
            <Footer/>
        </div>
    );
}

export default Events;