import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function MineEventDescription(props) {
    return (
        <>
            <div className="about-wrapper pt--120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 text-center text-lg-left">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Magical Litija and the Sitarjevec mine</h2>
                                    <p className="description">
                                        As part of the December events in Litija, which we named Magic Litija, there is also a wide area of events in and before the Sitarjevec mine. Events are well attended and attract more and more visitors every year. They are inviting the visitors with differentness and uniqueness, and some with nostalgia and a pleasant atmosphere.
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6 mt-4 ">
                            <div className="thumbnail">
                                <StaticImage
                                    alt="Magical Litija and the Sitarjevec mine"
                                    src="../../../assets/images/rudnik/december-rudnik-sitarjevec.jpeg"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper pt--60 bg_color--1">
                <div className="container">
                    <div className="row row--35 align-items-center text-center text-lg-left">
                        <div className="col-lg-6 col-md-12 order-1 order-lg-0 mt-4">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../../assets/images/rudnik/mineralne-jaslice.jpeg"
                                    alt="Mineral Cribs"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6 order-0 order-lg-1">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Mineral nativity scenes</h2>
                                    <p className="description">
                                        Visitors can visit the unique mineral cribs during December in the hall of the mine.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper pt--60">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-6 col-md-12">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Torchlight procession</h2>
                                    <p className="description">
                                        The traditional hike on the name day of St. Barbara, December the 4th. When thousands of lights are lit in Litija dwarfs and Litko greet the hikers. They start the procession from the old town and continue to the mine with lit torches.
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6">
                            <div className="thumbnail mt-4">
                                <StaticImage
                                    src="../../../assets/images/rudnik/pohod-z-baklami.jpeg"
                                    alt="Torchlight procession in Litija in December"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper pt--60 pb--120">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-6 col-md-12 order-1 order-lg-0 mt-4">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../../assets/images/rudnik/zive-jaslice.jpeg"
                                    alt="Live cribs"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-xl-6 order-0 order-lg-1">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Live nativity scenes</h2>
                                    <p className="description">
                                        They are another feature of the December developments in Litija. The Christmas story can be viewed by visitors on a projection screen in front of the entrance to the mine.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MineEventDescription;